<template>
    <div class="card p-1">
        <div class="card-body p-1">
            <ul class="nav nav-pills nav-justified">
                <li class="nav-item px-01">
                    <router-link :class="computeActivePath('/')" to="/">
                        <i class="mdi mdi-home-variant-outline font-size-18 mr-1 align-middle"></i>
                        <span class="d-none d-md-inline-block text-uppercase">{{$t('components.boxBreadcrumb.bacheca')}}</span>
                    </router-link>
                </li>
                <li class="nav-item px-01">
                    <router-link :class="computeActivePath('/banche-dati')" :to="{ name: routesEnum.COMPETITIONS }">
                        <i class="mdi mdi-school-outline font-size-18 mr-1 align-middle"></i>
                        <span class="d-none d-md-inline-block text-uppercase">{{$t('components.boxBreadcrumb.concorsi')}}</span>
                    </router-link>
                </li>
                <li class="nav-item px-01">
                    <router-link :class="computeActivePath('/materie')" :to="{ name: routesEnum.PATH }">
                        <i class="mdi mdi-timeline-text-outline font-size-18 mr-1 align-middle"></i>
                        <span class="d-none d-md-inline-block text-uppercase">{{$t('components.boxBreadcrumb.percorsi')}}</span>
                    </router-link>
                </li>
                <li class="nav-item px-01">
                    <router-link :class="computeActivePath('/bandi-in-scadenza')" :to="{ name: routesEnum.CALL }">
                        <i class="mdi mdi-newspaper-variant-outline font-size-18 mr-1 align-middle"></i>
                        <span class="d-none d-md-inline-block text-uppercase">{{$t('components.boxBreadcrumb.bandiInScadenza')}}</span>
                    </router-link>
                </li>
                <li class="nav-item px-01">
                    <router-link :class="computeActivePath('/store')" :to="{ name: routesEnum.STORE }">
                        <i class="mdi mdi-shopping-outline font-size-18 mr-1 align-middle"></i>
                        <span class="d-none d-md-inline-block text-uppercase">{{$t('components.boxBreadcrumb.store')}}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {routesEnums} from '@/helpers/enums'
    export default {
        name: "BoxBreadcrumb",
        data () {
            return {
                routesEnum: routesEnums
            }
        },
        methods: {
            computeActivePath (path) {
                if (this.$route.path === path
                    || path === '/banche-dati' && this.$route.name === routesEnums.COMPETITION_DETAIL_WRAPPER
                    || path === '/banche-dati' && this.$route.name === routesEnums.ADD_COMPETITION
                    || path === '/banche-dati' && this.$route.name === routesEnums.EXERCISE && this.$route.params.mode === undefined
                    || path === '/banche-dati' && this.$route.name === routesEnums.SUMMARY
                    || path === '/materie' && this.$route.name === routesEnums.ADD_PATH
                    || path === '/materie' && this.$route.name === routesEnums.PATH_DETAIL_WRAPPER
                    || path === '/materie' && this.$route.name === routesEnums.EXERCISE && this.$route.params.mode === 'path'
                ) {
                    return 'nav-link active'
                } else {
                    return 'nav-link'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .nav-link:hover {
            color: #fff;
            background-color: #3051D3;
        }

        .px-01 {
            padding-left: .15rem !important;
            padding-right: .15rem !important;
        }

        @media (max-width: 992px) {
            .font-size-18 {
                font-size: 0.91rem !important;
            }

            .mr-1 {
                margin-right: 0 !important;
            }

            .px-01 {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
</style>
