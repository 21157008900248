<template>
    <header id="page-topbar" style="z-index: 1000">

        <div class="navbar-header">
            <div class="container-fluid">
                <div class="float-right">

                    <div class="dropdown d-inline-block">
                        <button type="button" class="btn header-item waves-effect pl-0" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"> -->
                            <span class="d-none d-sm-inline-block mx-1">{{profile.email_comunicazioni}}</span>
                            <i class="mdi mdi-chevron-down d-inline-block"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <span class="dropdown-item-no-hover py-0">
                                <div class="text-muted text-right" style="font-size: 9pt!important">
                                    <span>{{$t('components.navbar.versione')}}: {{appVersion}}</span>
                                </div>
                            </span>
                            <!-- item-->
                            <a class="dropdown-item cursorPointer" @click="goTo(routesNames.PROFILE, true)">
                                <i class="mdi mdi-face-profile font-size-16 align-middle"></i>
                                <span class="px-2">{{$t('components.navbar.profilo')}}</span>
                            </a>
                            <a class="dropdown-item cursorPointer" @click="goTo('https://www.concorsando.it/blog/come-funziona-il-simulatore-quiz/', false)" target="_blank" rel="noopener">
                                <i class="mdi mdi-book font-size-16 align-middle"></i>
                                <span class="px-2">{{$t('components.navbar.guidaUtilizzo')}}</span>
                            </a>
                             <a class="dropdown-item cursorPointer" @click="goTo('https://www.concorsando.it/blog/contattaci', false)" target="_blank" rel="noopener">
                                <i class="mdi mdi-lifebuoy font-size-16 align-middle"></i>
                                <span class="px-2">{{$t('components.navbar.assistenzamenu')}}</span>
                            </a>
                            <!--<a class="dropdown-item" href="#"><i class="mdi mdi-lock font-size-16 align-middle mr-1"></i> Lock screen</a-->
                            <span class="dropdown-item-no-hover">
                                <i class="mdi mdi-brightness-2 font-size-16 align-middle"></i>
                                <span class="px-2">{{$t('components.navbar.modalitaNotturna')}}</span>
                                <div class="toggle-box d-inline-block">
                                    <input type="checkbox" name="checkbox1" id="toggle-box-checkbox" v-model="theme" @click="changeTheme()"/>
                                    <label for="toggle-box-checkbox" class="toggle-box-label-left"></label>
                                    <label for="toggle-box-checkbox" class="toggle-box-label"></label>
                                </div>
                            </span>
                            <div class="dropdown-divider"></div>
                            <a class="btn btn-danger btn-sm dropdown-item" type="button" @click="exit()"><i class="mdi mdi-logout font-size-16 align-middle mr-1"></i> {{$t('generale.esci')}}</a>
                        </div>
                    </div>
                </div>

                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <router-link to="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="../assets/images/new-image/concorsando.png" alt="" height="40">
                        </span>
                        <span class="logo-lg">
                            <img src="../assets/images/new-image/concorsando.png" alt="" height="40">
                        </span>
                    </router-link>

                    <router-link to="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="../assets/images/new-image/concorsando-white.png" alt="" height="40">
                        </span>
                        <span class="logo-lg">
                            <img src="../assets/images/new-image/concorsando-white.png" alt="" height="40">
                        </span>
                    </router-link>
                </div>

                <button type="button" class="btn btn-sm mr-2 font-size-16 d-lg-none header-item waves-effect waves-light" data-toggle="collapse" data-target="#topnav-menu-content">
                    <i class="fa fa-fw fa-bars"></i>
                </button>

            </div>
        </div>

    </header>
</template>

<script>
    import 'bootstrap'
    import $ from 'jquery'
    import { mapActions, mapGetters } from 'vuex'
    import { MODULES } from "@/store/modules-enum";
    import { AUTH_GETTERS } from "@/store/auth/getters-enum";
    import { AUTH_ACTIONS } from "@/store/auth/actions-enum";
    import {routesEnums, themeEnums} from "@/helpers/enums";

    export default {
        name: "Navbar",
        data () {
            return {
                word: '',
                appVersion: process.env.VUE_APP_VERSION,
                theme: false,
                routesNames: routesEnums
            }
        },
        computed: {
            ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.AUTHENTICATIONS, AUTH_GETTERS.PROFILE])
        },
        mounted() {
            this.init()
        },
        methods: {
            ...mapActions(MODULES.AUTH, [AUTH_ACTIONS.LOGOUT, AUTH_ACTIONS.CLEAR_AUTH]),
            exit () {
                this.logout().finally(() => {
                    $('html').removeClass('theme-dark').addClass('theme-light');
                    $('body').removeClass('theme-dark').addClass('theme-light');
                    localStorage.removeItem('theme')
                    this.$router.push({name: routesEnums.AUTH, query: { type: 'login' }})
                })
            },
            changeTheme () {
                if ($('body').hasClass('theme-light')) {
                    $('html').removeClass('theme-light').addClass('theme-dark');
                    $('body').removeClass('theme-light').addClass('theme-dark');
                    localStorage.setItem('theme', themeEnums.DARK);
                }
                else {
                    $('html').removeClass('theme-dark').addClass('theme-light');
                    $('body').removeClass('theme-dark').addClass('theme-light');
                    localStorage.setItem('theme', themeEnums.LIGHT);
                }
            },
            init() {
                $(document).ready(() => {
                    this.theme = localStorage.getItem('theme') === themeEnums.DARK;
                    $('.dropdown-menu').on('click', function(event) {
                        event.stopPropagation();
                    });
                })
            },
            goTo(path, isRouter) {
                $('.dropdown').dropdown('hide')
                if (isRouter) {
                    this.$router.push({name: path})
                } else {
                    window.open(path,'_blank','noopener')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        @import '../assets/css/toggle-theme';

        .nav-link:hover {
            color: #fff;
            background-color: #3051D3;
        }

        .dropdown-item-no-hover {
            display: block;
            width: 100%;
            padding: .4rem 1rem;
            clear: both;
            font-weight: 500;
            text-align: inherit;
            white-space: nowrap;
            border: 0;
        }

        .mdi-brightness-2::before {
            transform: rotate(145deg);
        }
    }
</style>
