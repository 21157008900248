<template>
    <div id="boxUser">
        <div class="card">
            <div class="card-body text-center">
                <!-- <div class="mb-2">
                    <a href="profilo.html"> <img class="rounded-circle avatar-lg" alt="200x200" src="assets/images/users/avatar-4.jpg" data-holder-rendered="true"></a>
                </div>
                <h4>Marco Abate</h4> -->
                <h6>{{ $t('components.boxUser.idUtente') }}: <strong>{{ profile.id_utente_concorsando }}</strong></h6>
                <template v-if="profile.livello > 1">
                    <h6>{{ $t('components.boxUser.haiADisposizione') }}: <strong
                            class="text-danger">{{ computeAvailableQuiz() }} Quiz</strong></h6>
                </template>

                <router-link v-if="profile.livello > 1" to="/premium"
                             @click.native="passaAPremium()"
                             class="btn btn-danger btn-block btn-rounded waves-effect ">
                    <i class="mdi mdi-star mr-1 mdi-spin"></i> <strong
                        class="text-uppercase">{{ $t('components.boxUser.passaAPremium') }}</strong>
                </router-link>
                <template v-if="profile.livello == 1">
                    <button tabindex="-1" class="btn btn-danger btn-block btn-rounded waves-effect mouse-disable mb-2">
                        <strong class="text-uppercase">{{ $t('components.boxUser.utentePremium') }}</strong>
                        <template v-if="dataScadenza">
                            <br/>
                            <span class="text-uppercase font-size-11">{{ $t('components.boxUser.finoA') }}{{ dataScadenza }}</span>
                        </template>
                    </button>
                    <router-link to="/promo-premium">
                        <strong class="text-uppercase">{{ $t('components.boxUser.promoPremium') }}</strong>
                    </router-link>
                </template>
            </div>
        </div>

        <div class="card p-3 bg-warning" v-if="profile.livello > 1 && profile.livello > 501">
            <span class="font-weight-bolder">{{ $t('components.boxUser.msgLimitQuiz') }}</span>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODULES} from "../store/modules-enum";
import {AUTH_GETTERS} from "../store/auth/getters-enum";
import _ from "lodash";
import moment from "moment";
import firebase from "firebase";

export default {
    name: "BoxUser",
    computed: {
        ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE]),
        dataScadenza() {
            const date = _.get(this.profile, "data_scadenza_premium");
            if (date) {
                const momentDate = moment(date);
                return momentDate.isValid() ? momentDate.locale('it').format('DD/MM/YYYY') : null
            } else {
                return null;
            }
        },
    },
    methods: {
        computeAvailableQuiz() {
            return (((502 - this.profile.livello) < 0) ? 0 : (502 - this.profile.livello))
        },
        passaAPremium() {
            firebase.analytics().logEvent('premium_button_clicked')
            window.fbq('trackCustom', 'premium_button_clicked')
        }
    }
}
</script>

<style scoped>

</style>
