<template>
    <div id="boxBlogNews">
        <template v-if="getBannersToShow().length > 0">
            <template v-for="(banner, index) in getBannersToShow()">
                <a :href="banner.link" target="_blank" rel="noopener">
                    <div class="card">
                        <div class="card-body text-center">
                                <img :src="banner.url_img" class="img-fluid">
                                <span v-if="banner.html !== undefined && banner.html !== null && banner.html !== ''" v-html="banner.html"></span>
                                <div class="text-center pt-2" v-else>
                                    <span class="text-uppercase" style="color: #495057">{{banner.testo}}</span>
                                </div>
                        </div>
                    </div>
                </a>
            </template>
        </template>
        <template v-else>
            <div class="card">
                <div class="card-body" style="height: 200px">
                </div>
            </div>
        </template>

        <!-- Ultime Banche Dati Pubblicate -->
        <!-- <div class="card" v-if="computeRouteName()">
            <div class="card-body">
                <h5 class="header-title mb-4">
                    <span v-if="computeRouteName()">{{$t('components.boxBlogNews.ultimiConcorsiPubblicati')}}</span>
                    <span v-else>{{$t('components.boxBlogNews.notizieDalBlog')}}</span>
                </h5>
                <template v-if="computeRouteName()">
                    <template v-for="(competition, index) in lastCompetitions">
                        <div class="media">
                            <div class="media-body">
                                <h6 class="text-success"><strong>{{competition.anno}}</strong></h6>
                                <h6>{{competition.ente.ente}}</h6>
                                <p class="text-muted" style="font-size:.80em">{{competition.concorso}}</p>
                                <div class="mt-1">
                                    <button type="button" v-if="competition.aggiuntoDallUtente !== undefined && competition.aggiuntoDallUtente" @click="setSelectedCompetitionDetail(competition)" class="btn btn-primary btn-sm btn-rounded">{{$t('generale.apri')}}</button>
                                    <button type="button" v-else @click="addCompetition(competition.id_concorso)" class="btn btn-primary btn-sm btn-rounded">{{$t('home.aggiungiConcorso')}}</button>
                                </div>
                            </div>
                        </div>
                        <hr>
                    </template>
                </template>

                <router-link to="/banche-dati" v-if="computeRouteName()" class="btn btn-block btn-light">{{$t('components.boxBlogNews.vaiAiConcorsi')}}</router-link>
                <a type="button" v-else class="btn btn-block btn-light">{{$t('components.boxBlogNews.vaiAlBlog')}}</a>
            </div>
        </div> -->
        <!-- /Ultime Banche Dati Pubblicate -->

        <!-- Modals -->
        <div id="modalError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="myModalLabel">{{$t('components.boxBlogNews.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body" class="modal-body">
                        <p id="message-body"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modals -->

        <!-- Modals -->
        <div id="modalErrorMainBlocker" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorMainBlockerLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorMainBlockerLabel">{{$t('components.boxBlogNews.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-blocker" class="modal-body">
                        <p id="message-body-blocker"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="$router.push({name: routesNames.HOME})" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modals -->
    </div>
</template>

<script>
    import _ from "lodash";
    import {mapGetters, mapActions} from 'vuex'
    import {MODULES} from "@/store/modules-enum";
    import {COMPETITION_GETTERS} from "@/store/competition/getters-enum";
    import $ from "jquery";
    import {COMPETITION_ACTIONS} from "@/store/competition/actions-enum";
    import {competitionDetailTabsEnums as tabsEnums} from "@/helpers/enums";
    import {routesEnums} from "@/helpers/enums";
    import CustomClient from "@/lib/CustomConcorsandoClient/api/CustomClient";

    const customClient = CustomClient.instance

    export default {
        name: "BoxBlogNews",
        data() {
            return {
                routesNames: routesEnums,
                banners: []
            }
        },
        computed: {
            ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.COMPETITIONS, COMPETITION_GETTERS.USER_COMPETITIONS]),
            lastCompetitions() {
                const competitionsOfUser = _.cloneDeep(this.userCompetitions)
                let competitionsFiltered = []
                _.cloneDeep(this.competitions).forEach(competition => {
                    if (competition.stato === 1 || competition.stato === 2) {
                        competitionsOfUser.forEach(userCompetition => {
                            if (userCompetition.id_concorso === competition.id_concorso) {
                                competition.aggiuntoDallUtente = true
                            }
                        })
                        competitionsFiltered.push(competition)
                    }
                })
                return competitionsFiltered.slice(0, 5)
            }
        },
        mounted() {
            this.getBanner()
        },
        methods: {
            ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_COMPETITION, COMPETITION_ACTIONS.GET_USER_COMPETITIONS, COMPETITION_ACTIONS.SET_SELECTED_COMPETITION]),
            addCompetition (id_concorso) {
                this.$emit('is-components-ready', 'boxBlogNews', false)
                const context = this
                this.getCompetition({
                    idConcorso: id_concorso,
                    opts: null
                })
                    .then(() => {
                        context.$emit('is-components-ready', 'boxBlogNews', true)
                        context.getUtenteConcorsi(id_concorso)
                    })
                    .catch(() => {
                        context.showModalError('components.boxBlogNews.modale.errore.aggiungiConcorso')
                    })
            },
            getBannersToShow () {
                if (this.computeRouteName()) {
                    return this.banners.slice(0, 5);
                }
                else {
                    return this.banners.slice(0, 3);
                }
            },
            getUtenteConcorsi (idConcorso) {
                if (this.computeRouteName()) {
                    this.$emit('is-components-ready', 'boxBlogNews', false)
                    const context = this
                    context.getUserCompetitions()
                        .then(() => {
                            this.$emit('is-components-ready', 'boxBlogNews', true)
                            if (idConcorso) {
                                context.setSelectedCompetitionDetail({ id_concorso: idConcorso })
                            }
                        })
                        .catch(() => {
                            context.showModalErrorBlocker('components.boxBlogNews.modale.errore.concorsiUtente')
                        })
                }
            },
            setSelectedCompetitionDetail (row) {
                this.$route.query.type = tabsEnums.EXERCISE
                let context = this
                this.setSelectedCompetition(row.id_concorso)
                    .then(() => {
                        if (context.$route.name !== context.routesNames.COMPETITION_DETAIL_WRAPPER) {
                            context.$router.push({ name: context.routesNames.COMPETITION_DETAIL_WRAPPER})
                        }
                    })
                    .catch(() => {
                        context.showModalError('components.boxBlogNews.modale.errore.concorsoNonTrovato')
                    })
            },
            computeRouteName () {
                return this.$route.name === this.routesNames.COMPETITION_DETAIL_WRAPPER
                    || this.$route.name === this.routesNames.SUMMARY
                    || this.$route.name === this.routesNames.PATH_DETAIL_WRAPPER
            },
            showModalError (message) {
                const context = this
                this.$emit('is-components-ready', 'boxBlogNews', true)
                $('#modalError').modal({backdrop: 'static', keyboard: false})
                $('#message-body').html(context.$t(message))
            },
            showModalErrorBlocker (message) {
                const context = this
                this.$emit('is-components-ready', 'boxBlogNews', true)
                $('#modalErrorMainBlocker').modal({backdrop: 'static', keyboard: false})
                $('#message-body-blocker').html(context.$t(message))
            },
            getBanner () {
                this.$emit('is-components-ready', 'boxBlogNews', false)
                customClient.getBanners()
                    .then((res) => {
                        this.$emit('is-components-ready', 'boxBlogNews', true)
                        this.banners = Array.isArray(res.response.banners) ? _.cloneDeep(res.response.banners.slice(0,5)) : [];
                    })
                    .catch(() => {
                        this.showModalError('components.boxBlogNews.modale.errore.banners')
                    })
            }
        }
    }
</script>

<style scoped>

</style>
