<template>
    <div id="layout">
        <loader class="loading" style="display: none"></loader>
        <div id="layout-wrapper" style="display: flex; flex-direction: column; min-height: 100vh;">
            <Navbar></Navbar>

            <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== -->
            <div class="main-content" :style="($route.name === routesNames.EXERCISE) ? 'overflow: visible' : ''">

                <div class="page-content">

                    <!-- Page-Title -->
                    <div class="page-title-box">
                    </div>
                    <!-- end page title end breadcrumb -->

                    <div class="page-content-wrapper">
                        <div class="container-fluid">
                            <div class="row">

                                <template v-if="$route.name !== routesNames.EXERCISE">
                                    <div class="col-xl-3">
                                        <BoxUser></BoxUser>
                                        <BoxBlogNews v-if="isLoading === false" v-on:is-components-ready="isComponentReady"></BoxBlogNews>
                                    </div>

                                    <div class="col-xl-9">
                                        <BoxBreadcrumb></BoxBreadcrumb>

                                        <router-view v-if="isLoading === false" v-on:is-components-ready="isComponentReady"/>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-xl-12">
                                        <BoxBreadcrumb></BoxBreadcrumb>

                                        <router-view/>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <Footer></Footer>
        </div>

        <div id="modalError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="myModalLabel">{{$t('layouts.main.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body" class="modal-body">
                        <p id="message-body"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" v-if="$route.name !== routesNames.HOME" id="button-ok" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="goToHome()">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-retry" class="btn btn-primary waves-effect waves-light" @click="sendAllRequests()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>

        <div id="modalTime" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalTimeLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="myModalTimeLabel">{{$t('layouts.main.modale.time.titolo')}}</h5>
                    </div>
                    <div id="modal-body-time" class="modal-body">
                        <h5 class="font-size-16">{{$t('layouts.main.modale.time.sottotitolo')}}</h5>
                        <p id="message-body-time"></p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
    </div>
</template>

<script>
    import 'bootstrap'
    import $ from 'jquery'
    import {mapActions, mapGetters} from 'vuex'
    import { MODULES } from "@/store/modules-enum";
    import { PATH_ACTIONS } from "@/store/path/actions-enum";
    import { COMPETITION_ACTIONS } from "@/store/competition/actions-enum";
    import { UTILITY_ACTIONS } from "@/store/utility/actions-enum";
    import Navbar from '../components/Navbar'
    import Footer from "../components/Footer";
    import BoxUser from "../components/BoxUser";
    import BoxBlogNews from "../components/BoxBlogNews";
    import BoxBreadcrumb from "../components/BoxBreadcrumb";
    import {UTILITY_GETTERS} from "@/store/utility/getters-enum";
    import {COMPETITION_GETTERS} from "@/store/competition/getters-enum";
    import {PATH_GETTERS} from "@/store/path/getters-enum";
    import {UTILITY_MUTATIONS} from "@/store/utility/mutations-enum";
    import {PROFILE_ACTIONS} from "@/store/profile/actions-enum";
    import {getDistanceWith, isTimeChecked, setTimeChecked} from "@/lib/utils";
    import {systemTimeDelay} from "@/config";
    import {AUTH_GETTERS} from "@/store/auth/getters-enum";
    import {routesEnums} from "@/helpers/enums";
    import Loader from "@/components/Loader";
    import _ from 'lodash'

    export default {
        name: "Main",
        data () {
            return {
                routesNames: routesEnums,
                isLoading: true,
                requests: []
            }
        },
        components: {
            BoxBreadcrumb,
            BoxBlogNews,
            BoxUser,
            Footer,
            Navbar,
            Loader
        },
        computed: {
            ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.COMPETITIONS, COMPETITION_GETTERS.ENTITIES]),
            ...mapGetters(MODULES.PATH, [PATH_GETTERS.PATHS]),
            ...mapGetters(MODULES.UTILITY, [UTILITY_GETTERS.GET_LOCAL_UPTIME]),
            ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE])
        },
        beforeCreate() {
            $('#app-body').attr('data-layout', 'horizontal')
            $('#app-body').attr('data-layout-size', 'boxed')
        },
        mounted() {
            this.setRequests()
            this.verifyUptimeMain()
        },
        methods: {
            ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_COMPETITIONS, COMPETITION_ACTIONS.GET_ENTITIES]),
            ...mapActions(MODULES.PATH, [PATH_ACTIONS.GET_PATHS]),
            ...mapActions(MODULES.UTILITY, [UTILITY_ACTIONS.GET_UPTIME]),
            ...mapActions(MODULES.PROFILE, [PROFILE_ACTIONS.GET_USER]),
            verifyUptimeMain () {
                const context = this
                if ( this.$route.name !== this.routesNames.HOME
                    && this.$route.name !== this.routesNames.ADD_COMPETITION
                    && this.$route.name !== this.routesNames.EXERCISE
                    && this.$route.name !== this.routesNames.ADD_PATH ) {
                    this.isComponentReady('sendAllRequests', false)
                    this.getUptime()
                        .then((res) => {
                            context.verifySystemTime(res.headers.date)
                            if (context.competitions.length < 1 || context.entities.length < 1 || context.paths.length < 1 ) {
                                context.sendAllRequests(false, res.body)
                            } else {
                                if (res.body > context.getLocalUptime) {
                                    context.sendAllRequests(false, res.body)
                                } else {
                                    context.sendAllRequests(true)
                                }
                            }
                        })
                        .catch(() => {
                            context.sendAllRequests(false)
                        })
                } else {
                    this.isLoading = false
                    context.isComponentReady('sendAllRequests', true)
                }
            },
            verifySystemTime (headersDate) {
                if (!isTimeChecked()) {
                    setTimeChecked(true);
                    const timeDiff = getDistanceWith(headersDate)
                    if (timeDiff > systemTimeDelay) {
                        $('#modalTime').modal('show')
                    }
                }
            },
            sendAllRequests (onlyUser, uptime) {
                $('#modalError').modal('hide')
                const context = this
                $('#button-retry').attr('disabled', 'disabled')
                const setupPromise = context.getUser(true).then(res => {
                    if (res.userCleared || !onlyUser) {
                        return Promise.all([
                            context.getCompetitions(context),
                            context.getEntities(context),
                            context.getPaths(context)
                        ])
                    }
                })
                setupPromise
                    .then(() => {
                        if (uptime !== undefined && uptime !== null) {
                            this.$store.commit(MODULES.UTILITY + "/" + UTILITY_MUTATIONS.SET_UPTIME, uptime)
                        }
                        $('#modalError').modal('hide')
                        context.isLoading = false
                        context.isComponentReady('sendAllRequests', true)
                    })
                    .catch(() => {
                        this.isComponentReady('sendAllRequests', true)
                        $('#modalError').data('bs.modal', null)
                        $('#modalError').modal({backdrop: 'static', keyboard: false})
                        $('#button-retry').removeAttr('disabled')
                        $('#message-body').html(context.$t('layouts.main.modale.errore.richieste'))
                    })
            },
            goToHome() {
                this.setRequests()
                this.isLoading = false
                $('#modalError').modal('hide')
                this.$nextTick(() => {
                    this.$router.push({ name: 'Home' })
                })
            },
            setRequests () {
                this.requests = [
                    {
                        label: 'boxBlogNews',
                        isComplete: true
                    },
                    {
                        label: 'sendAllRequests',
                        isComplete: true
                    },
                    {
                        label: 'calls',
                        isComplete: true
                    },
                    {
                        label: 'competitionDetail',
                        isComplete: true
                    },
                    {
                        label: 'home',
                        isComplete: true
                    },
                    {
                        label: 'addCompetition',
                        isComplete: true
                    },
                    {
                        label: 'addPath',
                        isComplete: true
                    },
                    {
                        label: 'competitions',
                        isComplete: true
                    },
                    {
                        label: 'exercise',
                        isComplete: true
                    },
                    {
                        label: 'pathDetail',
                        isComplete: true
                    },
                    {
                        label: 'paths',
                        isComplete: true
                    },
                    {
                        label: 'profile',
                        isComplete: true
                    },
                    {
                        label: 'recoveryPassword',
                        isComplete: true
                    },
                    {
                        label: 'store',
                        isComplete: true
                    },
                    {
                        label: 'summary',
                        isComplete: true
                    },
                    {
                        label: 'verifyEmail',
                        isComplete: true
                    },
                    {
                        label: 'tabellaRicercaBacheca',
                        isComplete: true
                    }
                ]
            },
            isComponentReady (label, state) {
                const request = _.find(this.requests, (r) => r.label === label)
                if (request !== undefined) {
                    request.isComplete = state
                }
                const isRequestPending = _.find(this.requests, (r) => r.isComplete === false)
                if (isRequestPending === undefined) {
                    $('.loading').hide()
                } else {
                    $('.loading').show()
                }
            }
        }
    }
</script>

<style scoped lang="css">
    .main-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .page-content {
        flex-grow: 1;
    }

</style>
